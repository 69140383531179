@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(./fonts/Montserrat-Light.ttf) format("truetype")
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./fonts/Montserrat-Regular.ttf) format("truetype")
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(./fonts/Montserrat-Medium.ttf) format("truetype")
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(./fonts/Montserrat-SemiBold.ttf) format("truetype")
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(./fonts/Montserrat-Bold.ttf) format("truetype")
}

@font-face {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(./fonts/Montserrat-ExtraBold.ttf) format("truetype")
}

html {
    font-size: 16px;
}

body {
    font-size: .875em;
    font-weight: 400;
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    line-height: 1.8
}

@media (min-width: 40em) {
    body {
        font-size: 1em
    }
}

/*1024*/
@media (min-width: 64em) {
    body {
        font-size: 1em
    }
}

/*1440*/
@media (min-width: 90em) {
    body {
        font-size: 1.125em
    }
}

/*1680*/
@media (min-width: 105em) {
    body {
        font-size: 1.125em
    }
}


a {
    text-decoration: none;
}


.App {
    text-align: center;
}

.App-logo {
    height: 55px;
    pointer-events: none;
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

svg.status_icon.green {
    text-shadow: 0 0 0.25em #2ede2e;
    filter: drop-shadow(0 0 0.25em #2ede2e);
    color: #1aa51a;
}

svg.status_icon.yellow {
    text-shadow: 0 0 0.25em #fff8dd;
    filter: drop-shadow(0 0 0.25em #fff8dd);
    color: rgb(255, 231, 144);
}

svg.status_icon.red {
    text-shadow: 0 0 0.25em #ff4d4d;
    filter: drop-shadow(0 0 0.25em #ff4d4d);
    color: red;
}

svg.status_icon.gray {
    text-shadow: 0 0 0.25em #ececec;
    filter: drop-shadow(0 0 0.25em #ececec);
    color: #c6c5c5;
}

svg.status_icon.aqua {
    text-shadow: 0 0 0.25em aqua;
    filter: drop-shadow(0 0 0.25em aqua);
    color: #27c8e1;
}

svg.status_icon.yellow {
    text-shadow: 0 0 0.25em #f3ef06;
    filter: drop-shadow(0 0 0.25em #f3ef06);
    color: #d9d600;
}

svg.status_icon.orange {
    text-shadow: 0 0 0.25em #f86f02;
    filter: drop-shadow(0 0 0.25em #f86f02);
    color: #a14900;
}


.bg-lightgray {
    background-color: lightgray;
}

.bg-lime {
    background-color: lime;
}

.bg-red {
    background-color: red;
}

.bg-aqua {
    background-color: aqua;
}
